import { VendorInvoiceOperation } from '../dto';

const getDefaults = (): VendorInvoiceOperation => ({
  id: 1,
  date: new Date('2023-01-01'),
  label: 'label',
  amount: 0,
});

export const getVendorInvoiceOperationMock = (p?: Partial<VendorInvoiceOperation>): VendorInvoiceOperation => ({
  ...getDefaults(),
  ...p,
});
