import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { VendorInvoice } from '@dougs/vendor-invoice/dto';

@Injectable({
  providedIn: 'root',
})
export class VendorInvoiceHttpService {
  constructor(private readonly http: HttpClient) {}

  createVendorInvoice(companyId: number, file: File): Observable<VendorInvoice> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<VendorInvoice>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/vendor-invoices?filename=${file.name}&type=attachment`,
      formData,
    );
  }

  deleteVendorInvoice(companyId: number, vendorInvoiceId: string): Observable<void> {
    return this.http.delete<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/vendor-invoices/${vendorInvoiceId}`,
    );
  }

  updateVendorInvoice(vendorInvoice: VendorInvoice): Observable<VendorInvoice> {
    return this.http.put<VendorInvoice>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${vendorInvoice.companyId}/vendor-invoices/${vendorInvoice.id}`,
      {
        memo: vendorInvoice.memo,
        paymentStatus: vendorInvoice.paymentStatus,
        amount: Math.abs(vendorInvoice.amount), // It is the “isRefund” value that determines whether the amount is negative or positive
        label: vendorInvoice.label,
        date: vendorInvoice.date ? new Date(vendorInvoice.date) : null,
        amountTva: vendorInvoice.amountTva,
        supplierName: vendorInvoice.supplierName,
        isRefund: vendorInvoice.isRefund,
        currency: vendorInvoice.currency,
      },
    );
  }

  attachOperation(operationId: number, vendorInvoice: VendorInvoice): Observable<VendorInvoice> {
    return this.http.post<VendorInvoice>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${vendorInvoice.companyId}/vendor-invoices/${vendorInvoice.id}/actions/attach-operation`,
      { operationId },
    );
  }

  detachOperation(operationId: number, vendorInvoice: VendorInvoice): Observable<VendorInvoice> {
    return this.http.post<VendorInvoice>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${vendorInvoice.companyId}/vendor-invoices/${vendorInvoice.id}/actions/detach-operation`,
      { operationId },
    );
  }

  getVendorInvoiceById(companyId: number, vendorInvoiceId: string): Observable<VendorInvoice> {
    return this.http.get<VendorInvoice>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/vendor-invoices/${vendorInvoiceId}`,
    );
  }

  getVendorInvoices(
    companyId: number,
    paymentStatus: 'paid' | 'not_paid_or_partially_paid',
    search?: string,
    offset = 0,
    limit = 20,
  ): Observable<VendorInvoice[]> {
    let params: HttpParams = new HttpParams()
      .append('paymentStatus', paymentStatus)
      .append('offset', offset * limit)
      .append('limit', limit);

    if (search) {
      params = params.append('search', search);
    }

    return this.http.get<VendorInvoice[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/vendor-invoices`,
      {
        params,
      },
    );
  }

  acceptMatchedOperation(companyId: number, vendorInvoiceId: string): Observable<VendorInvoice> {
    return this.http.post<VendorInvoice>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/vendor-invoices/${vendorInvoiceId}/actions/accept-matched-operation`,
      {},
    );
  }

  rejectMatchedOperation(companyId: number, vendorInvoiceId: string): Observable<VendorInvoice> {
    return this.http.post<VendorInvoice>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/vendor-invoices/${vendorInvoiceId}/actions/reject-matched-operation`,
      {},
    );
  }

  stopVendorInvoicePrefill(companyId: number, vendorInvoiceId: string): Observable<VendorInvoice> {
    return this.http.post<VendorInvoice>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/vendor-invoices/${vendorInvoiceId}/actions/abort-prefill`,
      {},
    );
  }
}
