import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { VendorInvoiceStats } from '@dougs/vendor-invoice/dto';
import { VendorInvoiceStatsHttpService } from '../http/vendor-invoice-stats.http';

@Injectable({
  providedIn: 'root',
})
export class VendorInvoiceStatsStateService extends StateService<VendorInvoiceStats> {
  vendorInvoiceStats$: Observable<VendorInvoiceStats> = this.select((state) => state);

  constructor(
    private readonly vendorInvoiceStatsHttpService: VendorInvoiceStatsHttpService,
    private readonly logger: LoggerService,
  ) {
    super();
  }

  async refreshVendorInvoiceStats(companyId: number): Promise<void> {
    try {
      this.setState(await lastValueFrom(this.vendorInvoiceStatsHttpService.getStats(companyId)));
    } catch (e) {
      this.logger.error(e);
    }
  }
}
