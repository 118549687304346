import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { RoutingService, URL } from '@dougs/core/routing';
import { SERVICE_STATE } from '@dougs/revenue/services/dto';
import { CompanyServicesStateService } from '@dougs/revenue/services/shared';

@Injectable({
  providedIn: 'root',
})
export class CanAccessToVendorInvoiceGuard {
  constructor(
    private readonly companyServicesStateService: CompanyServicesStateService,
    private readonly routingService: RoutingService,
  ) {}

  async canActivate(): Promise<boolean | UrlTree> {
    const state: SERVICE_STATE = this.companyServicesStateService.services?.accounting.state;

    if (
      [
        SERVICE_STATE.ONBOARDING,
        SERVICE_STATE.PENDING,
        SERVICE_STATE.ACTIVATED,
        SERVICE_STATE.SUSPENDED,
        SERVICE_STATE.OFFBOARDING,
        SERVICE_STATE.TERMINATED,
        SERVICE_STATE.UNKNOWN,
      ].includes(state)
    ) {
      return true;
    }

    return this.routingService.createUrl([URL.ACCOUNTING]);
  }
}
