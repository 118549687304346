import { CurrencyISOCode } from '@dougs/core/config-back';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { FileAttachment } from '@dougs/core/files';

export interface VendorInvoice {
  id: string;
  label: string;
  companyId: number;
  paymentStatus: VendorInvoicePaymentStatus;
  prefillStatus?: VendorInvoicePrefillStatus;
  sourceDocumentId: number;
  amount: number;
  amountTva: number;
  memo: string;
  createdAt: Date;
  updatedAt: Date;
  fileName: string;
  filePath: string;
  fileType: string;
  isRefund: boolean;
  isLocked: boolean;
  supplierName: string;
  date: Date | string;
  currency: CurrencyISOCode;
  receiptId: number;
  operations: VendorInvoiceOperation[];
  matchedOperation?: VendorInvoiceOperation;
  operationAttachments: VendorInvoiceOperationAttachment[];
  accrualOperationAttachment: VendorInvoiceOperationAttachment | null;
  progress?: number;
  file?: FileAttachment;
  hasBeenUploadedNow?: boolean;
  hasAlreadyBeenUploaded?: boolean;
}

export enum VendorInvoicePaymentStatus {
  PAID = 'paid',
  NOT_PAID = 'not_paid',
  PARTIALLY_PAID = 'partially_paid',
}

export enum VendorInvoicePrefillStatus {
  INITIALISED = 'initialised',
  NOT_PREFILLED = 'not-prefilled',
  PREFILLED = 'prefilled',
}

export interface VendorInvoiceOperationAttachment {
  id: number;
  isDeletable: boolean;
  isTaxReportProcessed?: boolean;
  operation: VendorInvoiceOperation;
}

export interface VendorInvoiceOperation {
  id: number;
  date: Date;
  label: string;
  amount: number;
  receiptOperationMatchId?: number;
}
