import { VendorInvoiceStats } from '../dto';

const getDefaults = (): VendorInvoiceStats => ({
  totalToPay: 0,
  totalAccountingYearPaid: 0,
  totalMonthPaid: 0,
  totalPaymentInProgress: 0,
});

export const getVendorInvoiceStatsMock = (p?: Partial<VendorInvoiceStats>): VendorInvoiceStats => ({
  ...getDefaults(),
  ...p,
});
