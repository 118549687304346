import { Injectable } from '@angular/core';
import { lastValueFrom, map, Observable } from 'rxjs';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { Operation } from '@dougs/operations/dto';
import { VendorInvoice, VendorInvoicePrefillStatus } from '@dougs/vendor-invoice/dto';
import { VendorInvoiceHttpService } from '../http/vendor-invoice.http';

interface VendorInvoiceState {
  paidVendorInvoices: VendorInvoice[];
  notPaidVendorInvoices: VendorInvoice[];
}

@Injectable({
  providedIn: 'root',
})
export class VendorInvoiceStateService extends StateService<VendorInvoiceState> {
  paidVendorInvoices$: Observable<VendorInvoice[]> = this.select((state) =>
    [...(state?.paidVendorInvoices ?? [])].sort(this.vendorInvoiceSortFn),
  );
  notPaidVendorInvoices$: Observable<VendorInvoice[]> = this.select((state) =>
    [...(state?.notPaidVendorInvoices ?? [])].sort(this.vendorInvoiceSortFn),
  );
  vendorInvoices$: Observable<VendorInvoice[]> = this.select(() => this.allVendorInvoices);
  prefilledNotPaidVendorInvoices$: Observable<VendorInvoice[]> = this.notPaidVendorInvoices$.pipe(
    map((notPaidVendorInvoices: VendorInvoice[]) =>
      notPaidVendorInvoices.filter(
        (vendorInvoice: VendorInvoice) => vendorInvoice.prefillStatus !== VendorInvoicePrefillStatus.INITIALISED,
      ),
    ),
  );
  prefilledPaidVendorInvoices$: Observable<VendorInvoice[]> = this.paidVendorInvoices$.pipe(
    map((paidVendorInvoices: VendorInvoice[]) =>
      paidVendorInvoices.filter(
        (vendorInvoice: VendorInvoice) => vendorInvoice.prefillStatus !== VendorInvoicePrefillStatus.INITIALISED,
      ),
    ),
  );

  constructor(
    private readonly vendorInvoiceHttpService: VendorInvoiceHttpService,
    private readonly logger: LoggerService,
  ) {
    super();
  }

  private get allVendorInvoices(): VendorInvoice[] {
    return (this.state?.notPaidVendorInvoices || [])
      .concat(this.state?.paidVendorInvoices || [])
      .sort(this.vendorInvoiceSortFn);
  }

  public findVendorInvoiceById(vendorInvoiceId: string): VendorInvoice | undefined {
    return this.allVendorInvoices.find((vendorInvoice) => vendorInvoice.id === vendorInvoiceId);
  }

  async createVendorInvoice(companyId: number, file: File): Promise<VendorInvoice | null> {
    try {
      const vendorInvoice: VendorInvoice = await lastValueFrom(
        this.vendorInvoiceHttpService.createVendorInvoice(companyId, file),
      );
      this.setState({
        notPaidVendorInvoices: [...(this.state?.notPaidVendorInvoices ?? []), vendorInvoice],
      });

      return vendorInvoice;
    } catch (e: any) {
      if (e.status === 409) {
        const errorResponse: { existingVendorInvoiceId: string } | undefined = e?.error?.error;
        if (errorResponse?.existingVendorInvoiceId) {
          const alreadyExistingVendorInvoice: VendorInvoice | null = await this.refreshVendorInvoiceById(
            companyId,
            errorResponse.existingVendorInvoiceId,
          );
          if (alreadyExistingVendorInvoice) {
            return { ...alreadyExistingVendorInvoice, hasAlreadyBeenUploaded: true };
          }
        }
      }

      this.logger.error(e);
      return null;
    }
  }

  async deleteVendorInvoice(vendorInvoice: VendorInvoice): Promise<boolean> {
    try {
      await lastValueFrom(this.vendorInvoiceHttpService.deleteVendorInvoice(vendorInvoice.companyId, vendorInvoice.id));
      this.setState({
        notPaidVendorInvoices: this.state.notPaidVendorInvoices.filter(
          (vendorInvoiceIterated) => vendorInvoice.id !== vendorInvoiceIterated.id,
        ),
        paidVendorInvoices: this.state.paidVendorInvoices.filter(
          (vendorInvoiceIterated) => vendorInvoice.id !== vendorInvoiceIterated.id,
        ),
      });

      return true;
    } catch (e) {
      this.logger.error(e);

      return false;
    }
  }

  async updateVendorInvoice(vendorInvoice: VendorInvoice): Promise<boolean> {
    try {
      const vendorInvoiceUpdated: VendorInvoice = await lastValueFrom(
        this.vendorInvoiceHttpService.updateVendorInvoice(vendorInvoice),
      );

      this.updateVendorInvoiceStateFromPaymentStatus(vendorInvoiceUpdated);

      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async updateVendorInvoiceWithoutUpdateState(vendorInvoice: VendorInvoice): Promise<VendorInvoice | null> {
    try {
      const vendorInvoiceUpdated: VendorInvoice = await lastValueFrom(
        this.vendorInvoiceHttpService.updateVendorInvoice(vendorInvoice),
      );

      return vendorInvoiceUpdated;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async refreshPaidVendorInvoices(companyId: number, search?: string, offset = 0, limit = 20): Promise<void> {
    try {
      const paidVendorInvoices: VendorInvoice[] = await lastValueFrom(
        this.vendorInvoiceHttpService.getVendorInvoices(companyId, 'paid', search, offset, limit),
      );
      this.setState({
        paidVendorInvoices: offset
          ? [...(this.state?.paidVendorInvoices || []), ...paidVendorInvoices]
          : paidVendorInvoices,
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async refreshNotPaidVendorInvoices(companyId: number, search?: string, offset = 0, limit = 20): Promise<void> {
    try {
      const notPaidVendorInvoices: VendorInvoice[] = await lastValueFrom(
        this.vendorInvoiceHttpService.getVendorInvoices(companyId, 'not_paid_or_partially_paid', search, offset, limit),
      );
      this.setState({
        notPaidVendorInvoices: offset
          ? [...(this.state?.notPaidVendorInvoices || []), ...notPaidVendorInvoices]
          : notPaidVendorInvoices,
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async attachOperation(operation: Operation, vendorInvoice: VendorInvoice): Promise<boolean> {
    try {
      const vendorInvoiceUpdated: VendorInvoice = await lastValueFrom(
        this.vendorInvoiceHttpService.attachOperation(operation.id, vendorInvoice),
      );
      this.updateVendorInvoiceStateFromPaymentStatus(vendorInvoiceUpdated);
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async detachOperation(operation: Operation, vendorInvoice: VendorInvoice): Promise<boolean> {
    try {
      const vendorInvoiceUpdated: VendorInvoice = await lastValueFrom(
        this.vendorInvoiceHttpService.detachOperation(operation.id, vendorInvoice),
      );
      if (this.state?.paidVendorInvoices && this.state?.notPaidVendorInvoices) {
        this.updateVendorInvoiceStateFromPaymentStatus(vendorInvoiceUpdated);
      }
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async refreshVendorInvoiceById(companyId: number, vendorInvoiceId: string): Promise<VendorInvoice | null> {
    try {
      const vendorInvoice: VendorInvoice = await lastValueFrom(
        this.vendorInvoiceHttpService.getVendorInvoiceById(companyId, vendorInvoiceId),
      );
      if (vendorInvoice) {
        this.updateVendorInvoiceStateFromPaymentStatus(vendorInvoice);
      }
      return vendorInvoice;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async acceptMatchedOperation(companyId: number, vendorInvoiceId: string): Promise<VendorInvoice | null> {
    try {
      const vendorInvoice: VendorInvoice = await lastValueFrom(
        this.vendorInvoiceHttpService.acceptMatchedOperation(companyId, vendorInvoiceId),
      );
      if (vendorInvoice) {
        this.updateVendorInvoiceStateFromPaymentStatus(vendorInvoice);
      }
      return vendorInvoice;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async rejectMatchedOperation(companyId: number, vendorInvoiceId: string): Promise<VendorInvoice | null> {
    try {
      const vendorInvoice: VendorInvoice = await lastValueFrom(
        this.vendorInvoiceHttpService.rejectMatchedOperation(companyId, vendorInvoiceId),
      );
      if (vendorInvoice) {
        this.updateVendorInvoiceStateFromPaymentStatus(vendorInvoice);
      }
      return vendorInvoice;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async stopVendorInvoicePrefill(companyId: number, vendorInvoiceId: string): Promise<VendorInvoice | null> {
    try {
      const vendorInvoice: VendorInvoice = await lastValueFrom(
        this.vendorInvoiceHttpService.stopVendorInvoicePrefill(companyId, vendorInvoiceId),
      );
      if (vendorInvoice) {
        this.updateVendorInvoiceStateFromPaymentStatus(vendorInvoice);
      }
      return vendorInvoice;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  public updateVendorInvoiceStateFromPaymentStatus(vendorInvoice: VendorInvoice): void {
    if (vendorInvoice.paymentStatus === 'paid') {
      this.setState({
        notPaidVendorInvoices: this.state?.notPaidVendorInvoices?.filter(
          (vendorInvoiceIterated) => vendorInvoiceIterated.id !== vendorInvoice.id,
        ),
        paidVendorInvoices: this.addOrUpdateFromState(vendorInvoice, this.state?.paidVendorInvoices),
      });
    } else {
      this.setState({
        paidVendorInvoices: this.state?.paidVendorInvoices?.filter(
          (vendorInvoiceIterated) => vendorInvoiceIterated.id !== vendorInvoice.id,
        ),
        notPaidVendorInvoices: this.addOrUpdateFromState(vendorInvoice, this.state?.notPaidVendorInvoices),
      });
    }
  }

  private addOrUpdateFromState(vendorInvoice: VendorInvoice, vendorInvoices: VendorInvoice[]): VendorInvoice[] {
    return vendorInvoices?.some((vendorInvoiceIterated) => vendorInvoiceIterated.id === vendorInvoice.id)
      ? vendorInvoices.map((vendorInvoiceIterated) =>
          vendorInvoiceIterated.id === vendorInvoice.id ? vendorInvoice : vendorInvoiceIterated,
        )
      : [...(vendorInvoices ?? []), vendorInvoice];
  }

  async updateVendorInvoiceFromSocket(companyId: number, vendorInvoiceId: string): Promise<VendorInvoice | null> {
    const currentVendorInvoice: VendorInvoice | undefined = this.allVendorInvoices.find(
      (vendorInvoice) => vendorInvoice.id === vendorInvoiceId,
    );
    if (currentVendorInvoice) {
      return await this.refreshVendorInvoiceById(companyId, vendorInvoiceId);
    }
    return null;
  }

  // Order status initialised first then null/undefined date first then date desc
  private vendorInvoiceSortFn(a: VendorInvoice, b: VendorInvoice): number {
    return a.prefillStatus === VendorInvoicePrefillStatus.INITIALISED &&
      b.prefillStatus === VendorInvoicePrefillStatus.INITIALISED
      ? 0
      : a.prefillStatus === VendorInvoicePrefillStatus.INITIALISED
        ? -1
        : b.prefillStatus === VendorInvoicePrefillStatus.INITIALISED
          ? 1
          : !a.date && !b.date
            ? 0
            : !a.date
              ? -1
              : !b.date
                ? 1
                : new Date(b.date).getTime() - new Date(a.date).getTime();
  }
}
