import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { VendorInvoiceStats } from '@dougs/vendor-invoice/dto';

@Injectable({
  providedIn: 'root',
})
export class VendorInvoiceStatsHttpService {
  constructor(private readonly http: HttpClient) {}

  getStats(companyId: number): Observable<VendorInvoiceStats> {
    return this.http.get<VendorInvoiceStats>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/vendor-invoices/stats`,
    );
  }
}
